<template>
  <div class="d-flex page-wrapper flex-column h-100 justify" :class="{'onSidebarOpen' : sidebarVisible}">
      <div class="card-receipt bg-white d-flex flex-column align-items-center color-black  " id="card-receipt">
          
          <p class="font-14 text-center  mb-1 padding-right-print top">{{selectedShiftTransaction.date}}</p>
          <span class="text-center font-14 padding-right-print top">Laporan Penjualan <br> Inti Health Store</span>
          <p class="text-center font-roboto font-400 mb-0 font-14 mt-4 padding-right-print top">{{selectedShiftTransaction.invoice[0] ? selectedShiftTransaction.invoice[0] : ''}} {{selectedShiftTransaction.income.length > 1 ? '-' : ''}}</p>
          <p class="text-center font-roboto font-400 font-14 padding-right-print top">{{selectedShiftTransaction.income.length > 1 ? selectedShiftTransaction.invoice[1] : ''}}</p>
          <div class="d-flex w-100 gap-1">
                <span class="font-roboto font-400 font-14 w-50">Penjualan Kotor</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(selectedShiftTransaction.total_income || 0 , 'Rp.')}}</span>
                </div>
          </div>
          <div class="d-flex w-100 gap-1">
                <span class="font-roboto font-400 font-14 align-self-start ps-4 w-50">Faktur Retail SDH</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(selectedShiftTransaction.total_income || 0, 'Rp.')}}</span>
                </div>
          </div>
          <div class="d-flex w-100 gap-1 mt-4">
                <span class="font-roboto font-400 font-14 align-self-start w-50">Penjualan Tunai</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(selectedShiftTransaction.payment_method['Tunai'] || 0, 'Rp.')}}</span>
                </div>
          </div>          
          <div class="d-flex w-100 gap-1">
                <span class="font-roboto font-400 font-14 align-self-start ps-4 w-50">Tunai Retail SDH</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(selectedShiftTransaction.payment_method['Tunai'] || 0, 'Rp.')}}</span>
                </div>
          </div>
          <div class="d-flex justify-content-between w-100 gap-1">
                <span class="font-roboto font-400 font-14 align-self-start w-50">Penjualan Non Tunai</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(selectedShiftTransaction.cashless_sale || 0, 'Rp.')}}</span>
                </div>
          </div>   
          <div class="d-flex justify-content-between w-100 gap-1">
                <span class="font-roboto font-400 font-14 align-self-start w-50">Merchant Retail SDH</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(selectedShiftTransaction.cashless_sale || 0, 'Rp.')}}</span>
                </div>
          </div>
          <div class="d-flex justify-content-between w-100 gap-1" v-for="(value, index) in selectedShiftTransaction.payment_method" :key="index">
            <template v-if="index.toLowerCase() !== 'tunai' && index.toLowerCase() !== 'manual'">
              <span class="font-roboto font-400 font-14 align-self-start ps-4 w-50">{{index}}</span>
              <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(value || 0, 'Rp.')}}</span>
                </div>
            </template>
          </div>
          <div class="d-flex justify-content-between w-100 gap-1">
                <span class="font-roboto font-400 font-14 align-self-start w-50">Transfer Retail SDH</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(selectedShiftTransaction.cashless_sale_item['Transfer Bank'] || 0, 'Rp.')}}</span>
                </div>
          </div>
          <div class="d-flex justify-content-end w-100 gap-1" v-for="(transfer, index) in selectedShiftTransaction.transfer_bank_items" :key="index">
                <div class="d-flex justify-contend-end font-roboto font-400 font-14 amount padding-right-print">
                      <span class="text-amount"> {{formatRupiah(transfer.amount, 'Rp.')}}</span>
                </div>
          </div>
          <div class="d-flex justify-content-between w-100 gap-1" v-if="'Manual' in selectedShiftTransaction.payment_method">
                <span class="font-roboto font-400 font-14 align-self-start w-50">Pendapatan Lain lain</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(selectedShiftTransaction.payment_method['Manual'] || 0, 'Rp.')}}</span>
                </div>
          </div>
          <div class=" border-b-black w-100 align-self-end mt-3"></div>
          <div class="d-flex justify-content-between w-100 gap-1 py-2">
                <span class="font-roboto font-400 font-14 align-self-start ps-4 w-50">Pendapatan Kotor</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(selectedShiftTransaction.total_income || 0, 'Rp.')}}</span>
                </div>
          </div>
          <div class=" border-b-black w-100 align-self-end"></div>
          <div class="d-flex justify-content-between w-100 gap-1 py-3" >
                <span class="font-roboto font-400 font-14 align-self-start w-50">Pengeluaran</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(selectedShiftTransaction.total_spending || 0, 'Rp.')}}</span>
                </div>
          </div>
          <div class=" border-b-black w-100 align-self-end"></div>
          <div class="d-flex justify-content-between w-100 gap-1 py-2">
                <span class="font-roboto font-400 font-14 align-self-start ps-4 w-50">Pendapatan Bersih</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah((selectedShiftTransaction.total_income - selectedShiftTransaction.total_spending) || 0, 'Rp.')}}</span>
                </div>
          </div>
          <div class=" border-b-black w-100 align-self-end"></div>
          <div class="d-flex justify-content-between w-100 gap-1 py-3" >
                <span class="font-roboto font-400 font-14 align-self-start w-50">Saldo Awal Kas</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(selectedShiftTransaction.initial_balance || 0, 'Rp.')}}</span>
                </div>
          </div>
          <div class="d-flex justify-content-between w-100 gap-1" >
                <span class="font-roboto font-400 font-14 align-self-start w-50">Penambahan</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(selectedShiftTransaction.additional_cash || 0, 'Rp.')}}</span>
                </div>
          </div>
          <div class="d-flex justify-content-between w-100 gap-1">
                <span class="font-roboto font-400 font-14 align-self-start ps-4 w-50">Pendapatan Tunai</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(selectedShiftTransaction.payment_method['Tunai'] || 0, 'Rp.')}}</span>
                </div>
          </div>
          <div class="d-flex justify-content-between w-100 gap-1 mt-3" >
                <span class="font-roboto font-400 font-14 align-self-start w-50">Pendapatan Tunai LL</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(selectedShiftTransaction.other_income || 0, 'Rp.')}}</span>
                </div>
          </div>
          <div class="d-flex justify-content-between w-100 gap-1" >
                <span class="font-roboto font-400 font-14 align-self-start w-50">Pengeluaran Tunai</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(selectedShiftTransaction.total_spending || 0, 'Rp.')}}</span>
                </div>
          </div>
          <div class="d-flex justify-content-between w-100 gap-1" v-for="(transaction, index) in selectedShiftTransaction.spending" :key="index">
                <span class="font-roboto font-400 font-14 align-self-start ps-4 w-50 text-break">{{transaction.note}}</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(transaction.amount || 0, 'Rp.')}}</span>
                </div>
          </div>
          <div class=" border-b-black w-100 align-self-end mt-3"></div>
          <div class="d-flex justify-content-between w-100 gap-1 py-2">
                <span class="font-roboto font-400 font-14 align-self-start w-50">Saldo Akhir Sebelum Setor</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(selectedShiftTransaction.cash_balance || 0, 'Rp.')}}</span>
                </div>
          </div>
          <div class=" border-b-black w-100 align-self-end"></div>
          <div class="d-flex justify-content-between w-100 gap-1 mt-3">
                <span class="font-roboto font-400 font-14 align-self-start w-50">Setoran Kasir</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(selectedShiftTransaction.cash_balance || 0, 'Rp.')}}</span>
                </div>
          </div>
          <div class="d-flex justify-content-between w-100 gap-1 mt-3">
                <span class="font-roboto font-400 font-14 w-50">Saldo Akhir Tunai</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(selectedShiftTransaction.cash_balance || 0, 'Rp.')}}</span>
                </div>
          </div>
          <div class="d-flex justify-content-between w-100 gap-1">
                <span class="font-roboto font-400 font-14 w-50">Pendapatan Non Tunai</span>
                <div class="d-flex  font-roboto font-400 font-14 w-50 amount justify-content-end padding-right-print">
                      <span class="text-amount"> {{formatRupiah(selectedShiftTransaction.cashless_sale || 0, 'Rp.')}}</span>
                </div>
          </div>
            <div class="d-flex justify-content-between w-100 mt-4">
              <div class="receipt-footer d-flex flex-column justify-content-between  text-center">
                  <span class="font-roboto font-400 font-14">Yang Menyerahkan</span>
                  <span class="font-roboto font-400 font-14">{{getActiveCashier.name}}</span>
              </div>
              <div class="receipt-footer d-flex flex-column justify-content-between text-center padding-right-print ">
                  <span class="font-roboto font-400 font-14">Yang Menerima</span>
                  <span class="font-roboto font-400 font-14">Ibu Irene</span>
              </div>
          </div>
          <div class="end-border" />
      </div>
      <div class="footer position-fixed bottom-0 w-100 d-flex  bg-white py-3 gap-2 px-4">
       
          <button class="button button--primary w-50 px-5" v-print="'#card-receipt'">
            <img src="@/assets/icons/print.svg" alt="print icon" class="me-1">
            Print Laporan
          </button>
           <button class="button button--outline-primary-2 w-50 px-5" @click="exportData">
            <img src="@/assets/icons/export-icon-green.svg" alt="print icon" class="me-1">
            Export Excel
          </button>
      </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import IhsMixin from '@/mixins/ihs.mixins.js'
import print from 'vue-print-nb'
import { saveAs } from 'file-saver';

export default {
    mixins: [IhsMixin],
    directives: {
        print   
    },
    data() {
      return {
            printObj: {
              id: "card-receipt",
              popTitle: 'good print',
              extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
            }
      }
    },
    computed: {
        ...mapState('home', ['sidebarVisible']),
        ...mapState('order', ['order']),
        ...mapState('shift', ['selectedShiftTransaction']),
        getActiveCashier(){
          return JSON.parse(localStorage.getItem('activeCashier'))
        },
        cashierName() {
          return JSON.parse(localStorage.activeCashier).name
        }
    },
    methods : {
      ...mapActions('shift', ['exportSelectedShiftTransaction']),
      ...mapMutations('home', ['setSidebarVisible']),
      
      async exportData() {
            console.log(this.selectedShiftTransaction)
        const date = this.selectedShiftTransaction.date.split('/').reverse().join('/')

          const response = await this.exportSelectedShiftTransaction(date)

          if(response.status === 200) {
              saveAs(response.data , `transaction-${new Date()}.xlsx`)
          }
      }
    },
    created() {
      this.setSidebarVisible(false)
    }
};
</script>


<style lang="scss">
      @media print {
      .navbar {
        display: none !important;
      }
      .footer {
        display: none !important;
      }
      .card-receipt {
        width: 58mm !important;
        border: 0 !important;
        padding: 0 !important;
        border-radius: 0 !important;
        padding-bottom: 10px !important;
        margin: 0 !important;
      }
      .font-14 {
        font-size: 12px !important;
      }
      html {
        padding-top: 10x !important;
        padding-bottom: 10px !important;
        margin-bottom: 15px !important;
      }
      @page {
          margin-bottom: 7cm;
      }
       body { margin-bottom: 25mm; }
      .page-wrapper {
        padding: 0 !important;
      }
      .receipt-footer {
        margin-bottom: 15px !important;
        padding-bottom: 15px !important;
      }
    }
</style>
<style lang="scss" scoped>
    .page-wrapper {
      padding: 80px 0 160px 0;
    }
    .padding-right-print {
      padding-right: 1.3mm;
      &.top {
        padding-left: 2mm !important;
      }
    }
    .card-receipt {
        width: 315px;
        padding: 28px 12px;
        margin: auto;

        border: 0.5px solid #CDCDCE;
        border-radius: 8px;
    }
    .amount {
      word-break: word-break;
    }
    .text-amount {
        text-align: end;
    }

    .border-b-black {
        border-bottom: 1px solid black;
    }

    .cashier-container {
      border-top: 1px dashed black;
      border-bottom: 1px dashed black;
    }

    .total-container {
        border-top: 1px dashed black;

    }

    .receipt-footer {
      height: 120px;
    }

  .payment-divider:before,
  .payment-divider:after {
    border-top: 1px dashed black;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  .payment-divider:before {
    right: 0.5em;
    margin-left: -50%;
  }

  .payment-divider:after {
    left: 0.5em;
    margin-right: -50%;
  }
  
  .page-wrapper {
    background: #F5F5F5;
    transition: margin 400ms ease-in-out;
    overflow-x: hidden;
    position: relative;
    &.onSidebarOpen {
      margin-left: 78px;
    }
  }

  .end-border {
      margin-top: 50px;
      border-top: 1px dashed black;
      width: 100%;

  }
</style>

